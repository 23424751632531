footer {
    background-image: -ms-linear-gradient(top, rgba(136,146,89,1) 0%, rgba(177,187,128,1) 100%);
    background-image: -moz-linear-gradient(top, rgba(136,146,89,1) 0%, rgba(177,187,128,1) 100%);
    background-image: -o-linear-gradient(top, rgba(136,146,89,1) 0%, rgba(177,187,128,1) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(136,146,89,1)), color-stop(100, rgba(177,187,128,1)));
    background-image: -webkit-linear-gradient(top, rgba(136,146,89,1) 0%, rgba(177,187,128,1) 100%);
    background-image: linear-gradient(to bottom, rgba(136,146,89,1) 0%, rgba(177,187,128,1) 100%);

    height: 36px;
    width: auto;
    display:-webkit-flex;
    display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;

}


footer{
    span{
        color: white;
        font-size: 0.7rem;
        margin-left: 15px;
    }
}

.mail{
    color: #6E2E11;
}
