.three-column-block {
    display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
}

.gallery-two {
    margin-top: 40px;
}

.gallery-buttons {
    display:-webkit-flex;
    display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

.galeria-taniec {
    height: 40px;
    width: 90px;
    background-image: url("../images/galeriataniec.png");
}

.galeria-art {
    height: 40px;
    width: 90px;
    background-image: url("../images/galeriaart.png");
}

.galeria-cwiczenia {
    height: 40px;
    width: 130px;
    background-image: url("../images/galeriacwiczenia.png");
}

.cwiczenia {
    height: 40px;
    width: 140px;
    background-image: url("../images/cwiczenia.png");
}
// Cwiczenia:
// .row > .column {
//   // padding: 0 2px;
// }
// .row:after {
//   content: "";
//   display: table;
//   clear: both;
// }
//
// .column {
//   float: left;
//   width: 16%;
// }

.preview {
    background-color: black;
    display:-webkit-flex;
    display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    // flex-wrap: wrap;
}

.column {
    padding: 8px;
}

.row {
    display:-webkit-flex;
    display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
/* The Modal (background) */

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 10px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
}
/* Modal Content */

.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1000px;
}
/* The Close Button */

.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}

.close:focus,
.close:hover {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

.mySlides {
    display: none;
}
/* Next & previous buttons */

.next,
.prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}
/* Position the "next button" to the right */

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */

.next:hover,
.prev:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
/* Number text (1/3 etc) */

.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
}

img.demo {
    opacity: 0.6;
}

.activated,
.demo:hover {
    opacity: 1;
}

img.hover-shadow {
    transition: 0.3s;
}

.hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
