@font-face {
    font-family: 'Butler';
    src: url('../fonts/butler_regular-webfont.woff2') format('woff2'),
         url('../fonts/butler_regular-webfont.woff') format('woff');
     }


a {
    color: #000;
    outline: none;
    text-decoration: none;
}

li {
    color: #873A19;

    span {
        color: black;
    }
}

h1, .h1 {
    font-size: 1.4rem;
    color: white;
    font-family: Butler;
}


hr {
    color: #c5c5c5;
    opacity: 0.3;
}

p{
    letter-spacing: 0.2px;
}

.li{

    margin: 0;
    margin-left: 15px;
    padding: 0;
}
