header {
    // display:-webkit-flex;
    // display:-webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    // align-items:  flex-end;
    // -webkit-align-items: flex-end;
    width: auto;
    height: 166px;
    background-image: url("../images/estes_header.png");
    background-size:100%;
}

.navigation-wrapper {
    width: 100%;
    padding-left: 40px;
    line-height: 304px;
}
