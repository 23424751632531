.button-primary {
    display: inline-block;
    position: relative;
    width: 130px;
    height: 28px;

    span {
        width: 100%;
        height: 100%;
        // position: absolute;
        top: 0;
        left: 0;
        display: block;
        background-image: -ms-linear-gradient(top, rgb(219,232,153) 0%, rgb(182,192,133) 100%);
        background-image: -moz-linear-gradient(top, rgb(219,232,153) 0%, rgb(182,192,133) 100%);
        background-image: -o-linear-gradient(top, rgb(219,232,153) 0%, rgb(182,192,133) 100%);
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgb(219,232,153)), color-stop(100, rgb(182,192,133)));
        background-image: -webkit-linear-gradient(top, rgb(219,232,153) 0%, rgb(182,192,133) 100%);
        background-image: linear-gradient(to bottom, rgb(219,232,153) 0%, rgb(182,192,133) 100%);
        transform-origin: 0 100;
        transition: .2s;
        text-align: center;
        line-height: 28px;
        -webkit-border-top-left-radius: 20px;
        -webkit-border-top-right-radius: 20px;
        -moz-border-radius-topleft: 20px;
        -moz-border-radius-topright: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-left: 2px;
        box-shadow: 3px 3px 5px #8E965B;
        font-family: Butler;
        letter-spacing: 0.2px;
        color: #617201;
    }

    &:hover,
    &.active {
        span {
            transform: translate(0px, 28px);
            -webkit-transform: translate(0px, 28px);
            color: #fff;
            background-image: -ms-linear-gradient(top, rgb(87,36,10) 0%, rgb(135,58,26) 100%);
            background-image: -moz-linear-gradient(top, rgb(87,36,10) 0%, rgb(135,58,26) 100%);
            background-image: -o-linear-gradient(top, rgb(87,36,10) 0%, rgb(135,58,26) 100%);
            background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgb(87,36,10)), color-stop(100, rgb(135,58,26)));
            background-image: -webkit-linear-gradient(top, rgb(87,36,10) 0%, rgb(135,58,26) 100%);
            background-image: linear-gradient(to bottom, rgb(87,36,10) 0%, rgb(135,58,26) 100%);
            -webkit-border-bottom-right-radius: 20px;
            -webkit-border-bottom-left-radius: 20px;
            -moz-border-radius-bottomright: 20px;
            -moz-border-radius-bottomleft: 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            -webkit-border-top-left-radius: 0;
            -webkit-border-top-right-radius: 0;
            -moz-border-radius-topleft: 0;
            -moz-border-radius-topright: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
