body{
    background-image: url("../images/background.png");
    font-family: Verdana;
    font-size: 12px;
}
.container{
    height: 608px;
    width: 1000px;
    margin: 0 auto;
}
.content{
    display:-webkit-flex;
    display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: 406px;
    width: auto;
    background-color: #BEC88F;
}


.capitalized{
    text-transform: uppercase;
}


.quote{
    font-family: "Times New Roman";
    font-size: 1.1em;
    color: #2B3501;

}
