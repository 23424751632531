@import "modules/colors";
@import "modules/mixins";
@import "partials/default";
@import "partials/all";
@import "partials/body";
@import "partials/footer";
@import "partials/header";
@import "partials/variables";
@import "partials/buttons";
@import "partials/gallery"
