.photo_slide {
    width: 215px;
    height: 355px;
    margin-left: 10px;
}

.main {
    width: 432px;
    height: 325px;
    margin-left: 30px;
    font-size: 0.9em;
}

.menu {
    width: 245px;
    height: 325px;
    margin-left: 30px;
    font-size: 0.9em;
}

.content {
    img {
        align-self: flex-end;
    }
}

.scroll {
    display:-webkit-flex;
    display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
}

.scroll-left {
    width: 50%;
    height: auto;
    margin-right: 15px;
}

.scroll-right {
    width: 50%;
    height: auto;
    margin-left: 15px;
}

.heading-left {
    margin-top: 10px;
    width: 400px;
    float: left;
}

.res {
    width: 247px;
    height: 115px;
    float: left;
    background-image: url("../images/button_floral.png");
    .button{
        height: 33px;
        width: 210px;
        margin-top: 35px;
        margin-left: 20px;
        background-image: url("../images/button.png");

         span{
             color: #fff;
             line-height: 33px;
             padding-left: 20px;
             font-size: 0.9rem;
             text-shadow: 0px 0px 25px #000000;
             display: block;
             height: 33px;
             width: 210px;
            //  box-shadow:0px 0px 21px #303030;
         }
    }
}

.scroll-text {
    z-index: 99999;
}

.map {
    margin-top: 50px;
    box-shadow: -1px 0 25px #CFD7A2;
    height: 120px;
}

.two-row-block {
    width: 650px;
    height: 266px;
    padding-top: 10px;
}

.upper-row {
    height: 125px;
    width: 100%;
    display:-webkit-flex;
    display:-webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
}

.lower-row {
    height: 125px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.team {
    height: 120px;
    width: 40%;
    float: left;
}

.team {
    img {
        float: left;
        margin-right: 15px;
    }

    a {
        display: block;
    }
}

.opinie {
    height: 120px;
    width: 40%;
    float: left;
}

.opinie {
    img {
        float: left;
        margin-right: 15px;
    }

    a {
        display: block;
    }
}

.partnerzy {
    img {
        padding-top: 30px;
    }
}

.partnerzy {
    height: 120px;
    width: 40%;
}

.back {
    padding-top: 70px;
    padding-left: 170px;
    float: left;

    span {
        color: #939D5B;

        &:hover {
            color: #863A18;
        }
    }
}

.backbutton {
    color: #939D5B;

    &:hover {
        color: #863A18;
    }
}

.team-left {
    width: 110%;
    height: auto;
}
